

import TopLevelBlock from 'DataTypes/TopLevelBlock';
import Reporter from 'Reporter/Reporter';
import {tr} from 'utils/Translation';


export default class BlockParseError extends TopLevelBlock {

    errorMessage: string;
    datasize: number;
    
    constructor(errorMessage: ArrayBuffer|string) {
        super();

        this.errorMessage = errorMessage.toString();
        this.datasize = 0;
    }

    size() {
        return this.datasize + 5 + (this.hasSignature ? 5 + 128 : 0);
    }

    className() {
        return "BlockParseError";
    }

    title() {
        return "Block Parse Error";
    }

    toString() {
		return "Block Parse Error: " + this.errorMessage;
    }

    printOn(report: Reporter) {
        if (report.allowSvg()) {
            report.tagValuePair(tr("errorMessage"), `<h3 style="color: red">${this.errorMessage}</h3>`);
        } else {
            report.tagValuePair(tr("errorMessage"), this.errorMessage.toString());
        }
    }
}

