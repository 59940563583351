

export default class PictureGenerator {

    collected: string; 

    constructor() {
        this.collected = '';
    }

}